.info_box_choose {
  // display: none;
  // text-align: center;
  margin: 0 auto 60px;

  p {
    font-size: 12px;
    line-height: 1.389;
    max-width: 542px;
    margin: 20px auto 0;
  }

 
}

@media (max-width: 550px) {
  .info_box_choose {
    padding: 24px 24px 0;
    margin-bottom: 0;

    .el-button {
      min-width: 120px;
    }
  }
}
