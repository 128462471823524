.box_card_top {
  background-color: #ffffff;
  margin-bottom: 40px;
  position: relative;

  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
    color: $primary-purple;
    margin-bottom: 32px;
  }

  /deep/ .info_box {
    font-size: 16px;
    color: $primary-purple;

      position: relative;

      >img {
        position: absolute;
        @include rtl-sass-prop(right, left, 0);
        top: -164px;
      }

    p:not(:last-child) {
      margin-bottom: 20px;
    }

    ul {
      @include rtl-sass-prop(padding-left, padding-right, 38px);

      li {
        margin-bottom: 15px;
        list-style: decimal;
        @include rtl-sass-prop(padding-left, padding-right, 1em);
      }
    }

    a {
      color: #90bbea;
      text-decoration: underline;
    }
  }

  /deep/ .channel_box {
    margin-top: 40px;

    p {
      margin-bottom: 10px;
      color: $primary-green;
      font-size: 18px;
    }

    .el-select {
      width: 30%;
    }
  }

  .img {
    position: absolute;
    @include rtl-sass-prop(right, left, 60px);
    top: 46px;
    img {
      height: 95px;
    }
  }
}

.box_card_bottom {
  margin-top: 60px;
  border: $container-border;
  border-radius: $container-radius;
  padding: 50px;

  /deep/ .form_main {
    ul {
      li {
        width: 44%;

        .default_info {
          font-size: 12px;
          display: block;
          color: #6f90bc;
          margin-top: 13px;
          margin-bottom: 45px;
        }

        .lessMarginBottom.el-form-item {
          margin-bottom: 0;
        }

        &.data {
          opacity: 0.8;
          font-size: 16px;
          font-weight: 200;
          line-height: 1.64;
          color: #00063b;
          p {
            margin-bottom: 10px;
          }
        }

        &.pay {
          margin-bottom: 45px;
        }
      }
    }

    .form_button {
      text-align: center;
    }
  }
}

.desc_box {
  font-size: 16px;
  color: $primary-purple;
  margin: 40px 0;

  > ul {
    > li {
      margin-bottom: 15px;
      width: 100% !important;
    }
  }

  a {
    color: #90bbea;
    text-decoration: underline;
  }
}

.currencies_list {
  padding-left: 28px;
}

@media (max-width: 768px) {
  .box_card_top > img {
    @include rtl-sass-prop(right, left, 30px);
  }
}

@media (max-width: 767px) {
  .box_card_top {
    padding: 0;
    h4 {
      padding: 24px 24px 0;
      margin-bottom: 0;
      font-size: 16px;
    }

    .info_box {
      padding: 24px;
      font-size: 14px;

      ul {
        @include rtl-sass-prop(padding-left, padding-right, 25px);
        li {
          @include rtl-sass-prop(padding-left, padding-right, 0.5em);
        }
      }
    }

    .img {
      position: relative;
      top: unset;
      @include rtl-sass-prop(right, left, unset);
      text-align: center;
      border-top: solid 1px #cccfd4;
      padding: 10px 0;
    }
  }

  .box_card_bottom {
    padding: 45px 24px 24px;

    /deep/ .form_main ul li {
      width: 100%;

      &.data {
        font-size: 14px;
        color: #00063b;
        margin-bottom: 20px;
      }
    }
  }
}
